import React from "react";
import {Heading, List, ListItem, Stack, Text} from "@chakra-ui/react";

export const BlogPost2 = () => {
    return (
        <Stack spacing={3}>
            <Text>Removing a tree from your Johnson City property is sometimes necessary. While tree removal might seem
                straightforward, there are essential steps and safety precautions that homeowners should follow.</Text>
            <Heading as="h3" fontSize={'xl'}>Here are the essential steps to safe tree removal:</Heading>
            <List styleType="disc">
                <ListItem>Evaluate the situation. Not every tree needs to be removed. Sometimes, pruning or treatment
                    can save it.</ListItem>
                <ListItem>Assess the risk. Consider the tree's size, health, and proximity to structures. Larger trees
                    or those near power lines or buildings require extra caution.</ListItem>
                <ListItem>Use appropriate safety gear. This includes helmets, safety glasses, sturdy footwear, and
                    gloves.</ListItem>
                <ListItem>Plan your escape route. Unexpected events can occur, and you should be ready to move
                    quickly.</ListItem>
                <ListItem>Consider hiring professionals. Tree removal can be dangerous and should be done by trained
                    professionals whenever possible.</ListItem>
            </List>
            <Text>Remember, safety comes first. If you're not sure about any part of the tree removal process, it's best
                to call Johnson City Tree Service experts for assistance.</Text>
        </Stack>
    )
}