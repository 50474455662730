export const faqs = [
    {
        "question": "Can tree removal damage the foundation?",
        "answer": "Yes, tree removal can potentially damage the foundation of a property, especially if the roots are deeply embedded and intertwined with the foundation."
    },
    {
        "question": "Can tree removal be deducted from taxes?",
        "answer": "Tree removal typically cannot be deducted from taxes unless it is deemed necessary for the maintenance or safety of the property according to local tax laws. Consult with a tax professional for specific deductions."
    },
    {
        "question": "Can tree removal be covered by insurance?",
        "answer": "In certain cases, tree removal may be covered by insurance if the tree poses a direct threat to property or if it has fallen due to a covered peril such as a storm. Check with your insurance provider for coverage details."
    },
    {
        "question": "Do tree removal companies need to be licensed?",
        "answer": "Licensing requirements for tree removal companies vary by state and locality. In many areas, tree removal companies need to be licensed and insured to operate legally. Always verify the credentials of the company before hiring."
    },
    {
        "question": "Do tree removal companies sell wood?",
        "answer": "Many tree removal companies offer the option to sell wood from the trees they remove. This can be a cost-effective way to dispose of the tree while providing firewood or lumber for other purposes."
    },
    {
        "question": "How much does tree removal cost?",
        "answer": "The cost of tree removal varies based on factors such as the size and location of the tree, accessibility, and additional services required (such as stump grinding). On average, tree removal can range from a few hundred to several thousand dollars."
    },
    {
        "question": "How to negotiate tree removal?",
        "answer": "When negotiating tree removal services, consider obtaining multiple quotes from different companies, clarifying the scope of work, and discussing any potential discounts or payment options. Be sure to choose a reputable company with proper credentials and insurance."
    },
    {
        "question": "What is tree pruning?",
        "answer": "Tree pruning is the practice of selectively removing specific branches or stems from a tree to improve its health, structure, and appearance. Pruning can also help prevent disease, encourage fruit production, and reduce the risk of falling limbs."
    },
    {
        "question": "What is stump removal?",
        "answer": "Stump removal involves the complete extraction of the tree stump and its root system from the ground. This process typically requires specialized equipment such as stump grinders and may incur additional costs beyond standard tree removal."
    },
    {
        "question": "Does the city remove trees?",
        "answer": "Many cities offer tree removal services for public trees located on city-owned property or within public rights-of-way. However, the city may not remove trees on private property unless they pose a safety hazard or violate local ordinances."
    },
    {
        "question": "How do I remove tree stumps or get tree stump removal?",
        "answer": "Tree stump removal can be accomplished using various methods, including stump grinding, chemical treatments, or manual excavation. Hiring a professional tree service is often the safest and most efficient way to remove tree stumps."
    },
    {
        "question": "Who removes trees for free?",
        "answer": "Some municipalities or nonprofit organizations may offer free tree removal services for certain qualifying situations, such as hazardous trees threatening public safety or environmental conservation projects. Check with local agencies for available programs."
    },
    {
        "question": "Does anyone remove trees for free?",
        "answer": "While it's uncommon for private companies to offer free tree removal services, some may provide discounted or pro bono services for community projects or charitable causes. Contact local tree service providers to inquire about potential options."
    },
    {
        "question": "Why is tree removal important?",
        "answer": "Tree removal is important for various reasons, including eliminating safety hazards, preventing property damage, maintaining healthy forest ecosystems, and clearing land for development or landscaping purposes."
    },
    {
        "question": "What does tree removal cost so much?",
        "answer": "The cost of tree removal reflects the labor, equipment, expertise, and potential risks involved in safely removing trees. Factors such as tree size, location, complexity, and disposal fees contribute to the overall expense."
    },
    {
        "question": "Can I ask the city to remove a tree?",
        "answer": "You can typically request tree removal from the city for public trees posing safety hazards or located within public rights-of-way. For trees on private property, you may need to hire a private tree service or obtain permission from the property owner."
    },
    {
        "question": "When to cut tree branches",
        "answer": "It's generally recommended to prune tree branches during the dormant season, typically late winter or early spring, when the tree is not actively growing. However, dead, damaged, or hazardous branches should be removed promptly regardless of the season."
    },
    {
        "question": "Does tree removal increase property value?",
        "answer": "In some cases, removing certain trees may improve property value by enhancing aesthetics, reducing maintenance costs, and eliminating safety risks. However, the impact on property value can vary depending on factors such as tree species, location, and overall landscaping."
    },
    {
        "question": "Does tree removal include removing roots?",
        "answer": "Tree removal services typically include cutting down the tree and removing the above-ground portions. However, complete root removal may require additional excavation and is often considered a separate service."
    }
];
