export const posts = [
    {
        id: '1',
        title: 'Tree Pruning Guide - Johnson City Tree Service',
        metaTitle: 'Your Comprehensive Guide to Tree Pruning in Johnson City | Johnson City Tree Service Blog',
        excerpt: 'Are you looking to understand tree pruning and its importance better? This article shares the ultimate guide for Johnson City homeowners.',
        slug: 'ultimate-guide-to-tree-pruning-in-johnson-city',
        tags: ['tree pruning', 'guide', 'Johnson City'],
        metaDescription: 'Curious about tree pruning in Johnson City? Discover our professional guide to keep your trees in optimal health and enhance their aesthetic appeal.',
        lastModified: new Date('2023-12-03').toISOString(),
    },
    {
        id: '2',
        title: 'Safe Tree Removal Guide | Johnson City Tree Service',
        metaTitle: 'How to Safely Remove a Tree in Johnson City | Johnson City Tree Service Blog',
        excerpt: 'Removing a tree can sometimes be a necessity. This article provides essential steps to safe and efficient tree removal for Johnson City homeowners.',
        slug: 'essential-steps-to-safe-tree-removal-in-johnson-city',
        tags: ['tree removal', 'safety', 'Johnson City'],
        metaDescription: 'Essential steps for safe tree removal in Johnson City. Contact us for expert tree care services.',
        lastModified: new Date('2023-12-15').toISOString(),
        createdAt: new Date('2023-12-15').toISOString(),
    },
    {
        id: '3',
        title: 'Benefits of Professional Tree Trimming | Johnson City Tree Service',
        metaTitle: 'Why You Should Consider Professional Tree Trimming in Johnson City | Johnson City Tree Service Blog',
        excerpt: 'Considering tree trimming for your property? Discover the benefits of professional tree trimming services in Johnson City with our latest blog post.',
        slug: 'benefits-of-professional-tree-trimming-in-johnson-city',
        tags: ['tree trimming', 'benefits', 'Johnson City'],
        metaDescription: 'Discover the advantages of professional tree trimming in Johnson City with expert insights from our blog. Contact us for top tree care services.',
        lastModified: new Date('2023-12-20').toISOString(),
        createdAt: new Date('2023-12-20').toISOString(),
    },
    {
        id: '4',
        title: 'Stump Grinding Guide - Johnson City Tree Service',
        metaTitle: 'Your Guide to Stump Grinding in Johnson City | Johnson City Tree Service Blog',
        excerpt: 'Have an unsightly stump in your yard? Learn about the process of stump grinding in Johnson City, TN, and why it can be crucial for your property.',
        slug: 'understanding-stump-grinding-in-johnson-city',
        tags: ['stump grinding', 'process', 'Johnson City'],
        metaDescription: 'Learn about the benefits of stump grinding in Johnson City with our informative blog post. Contact us for professional tree service in Johnson City, TN.',
        lastModified: new Date('2024-1-04').toISOString(),
        createdAt: new Date('2024-1-04').toISOString(),
    },
]
