import React from "react";
import {Heading, List, ListItem, Stack, Text} from "@chakra-ui/react";

export const BlogPost3 = () => {
    return (
        <Stack spacing={3}>
            <Text>Tree trimming is an essential part of maintaining the aesthetics and health of the trees on your Johnson City property. But what benefits can you expect from professional tree trimming?</Text>
            <Heading as="h3" fontSize={'xl'}>Here are the benefits of professional tree trimming:</Heading>
            <List styleType="disc">
                <ListItem>Promotes tree health: Trimming removes dead or dying branches, helping prevent further decay.</ListItem>
                <ListItem>Improves overall appearance: Properly trimmed trees enhance the aesthetics of your property.</ListItem>
                <ListItem>Increases sun exposure and air circulation: Both are beneficial for the tree's overall health.</ListItem>
                <ListItem>Identifies potential problems: Regular tree trimming can help identify diseases or pests early.</ListItem>
                <ListItem>Enhances fruit production: For fruit trees, regular trimming can improve the size and quantity of the crop.</ListItem>
            </List>
            <Text>To enjoy these benefits, consider hiring a professional like Johnson City Tree Service for your tree trimming needs. Their expertise ensures safe and effective service.</Text>
        </Stack>
    )
}