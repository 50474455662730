import React from 'react';
import PageHeader from '../Components/common/PageHeader';
import {SEO} from "../Components/SEO/SEO";
import {Box, Link, List, ListItem} from "@chakra-ui/react";

const urls = [
    "https://www.manta.com/c/m1w1b56/johnson-city-nexus-tree-service",
    "https://www.hotfrog.com/company/a79b271427875bfbb875fdc9f3cc2106",
    "https://www.n49.com/biz/5788188/johnson-city-nexus-tree-service/",
    "https://tn-johnson-city.cataloxy.us/firms/johnsoncitytreeservice.com.htm",
    "https://www.callupcontact.com/b/businessprofile/Johnson_City_Nexus_Tree_Service/8854242",
    "https://www.zeemaps.com/map?group=4877916&add=1",
    "http://www.askmap.net/location/6794886/united-states/johnson-city-nexus-tree-service",
    "https://www.yplocal.com/home-garden/johnson-city-nexus-tree-service",
    "https://www.sbnation.com/users/johnsonctreeservice",
    "https://www.adlocalpages.com/home-services/johnson-city-nexus-tree-service",
    "https://www.nextbizthing.com/home-20-garden/johnson-city-nexus-tree-service",
    "https://www.freelistingusa.com/listings/johnson-city-nexus-tree-service",
    "http://www.travelful.net/location/5346803/united-states/johnson-city-nexus-tree-service",
    "http://www.place123.net/place/johnson-city-nexus-tree-service-.-united-states",
    "https://teleadreson.com/johnson-city-nexus-tree-service,-TvORAE7zkQA.html",
    "https://www.cgmimm.com/local-business/johnson-city-nexus-tree-service",
    "http://where2go.com/binn/b_search.w2g?function=detail&type=power&listing_no=2125267&_UserReference=7F0000014652622331682F54A80865965918",
    "https://trueen.com/business/listing/johnson-city-nexus-tree-service/388935",
    "https://smallbusinessusa.com/listing/johnsonctreeservice.html",
    "https://www.iformative.com/product/tree-service-p2393207.html",
    "https://www.bizbangboom.com/business-services/johnson-city-nexus-tree-service",
    "https://www.nextbizmaker.com/business-services/johnson-city-nexus-tree-service",
    "https://www.announceamerica.com/home-and-garden/johnson-city-nexus-tree-service",
    "https://www.bizmakersamerica.org/business-services/johnson-city-nexus-tree-service",
    "https://www.youbiz.com/listing/johnson-city-nexus-tree-service.html",
    "https://www.bizmaker.org/business-services/johnson-city-nexus-tree-service",
    "https://www.biztobiz.org/business-services/johnson-city-nexus-tree-service",
    "https://www.homify.com/professionals/9573124/johnson-city-nexus-tree-service",
    "https://www.earthmom.org/home-services/johnson-city-nexus-tree-service",
    "https://www.linkcentre.com/profile/johnsonctreeservice/",
    "https://www.cleansway.com/business/johnson-city-nexus-tree-service",
    "https://www.b2bco.com/johnsoncitytreeservice",
    "https://www.consultsdirect.com/consultant/johnson-city-nexus-tree-service",
    "https://www.finditangeles.com/services/johnson-city-nexus-tree-service",
    "https://flokii.com/businesses/view/118391/johnson-city-nexus-tree-service",
    "https://i.imgur.com/c9ICGb2.png",
    "https://www.anibookmark.com/business/johnson-city-nexus-tree-service-bs182770.html",
    "https://www.ilistbusiness.com/listing/johnson-city-nexus-tree-service/",
    "https://serverfault.com/users/1069077/tree-service?tab=profile",
    "https://www.preferredprofessionals.com/home-services/johnson-city-nexus-tree-service",
    "https://superuser.com/users/1877614/tree-service?tab=profile",
    "https://411freedirectory.com/listing/johnson-city-nexus-tree-service-592439",
    "https://activdirectory.net/listing/johnson-city-nexus-tree-service-803992",
    "https://www.thelululist.com/brown-and-black-owned-home-services/johnson-city-nexus-tree-service",
    "https://directory6.org/listing/johnson-city-nexus-tree-service-646246",
    "https://stackoverflow.com/users/23194019/tree-service?tab=profile",
    "https://www.directory2020.com/home-improvement-tools/johnson-city-nexus-tree-service",
    "https://www.moneysaversguide.com/business-services/johnson-city-nexus-tree-service",
    "http://www.click4homeservices.com/tree-services-service/johnson-city-nexus-tree-service",
    "https://www.ailoq.com/johnson-city-nexus-tree-service",
    "https://bizbookusa.com/listing/johnson-city-nexus-tree-service/",
    "https://askubuntu.com/users/1757828/tree-service?tab=profile",
    "https://www.getlisteduae.com/listings/johnson-city-nexus-tree-service",
    "https://www.localstar.org/johnson-city-nexus-tree-service",
    "https://mathoverflow.net/users/520081/tree-service?tab=profile",
    "https://www.webwiki.com/johnsoncitytreeservice.com",
    "https://cally.com/event/index/f6wx76nr7p2nf32d",
    "https://www.bunnydirectories.com/local-deals/johnson-city-nexus-tree-service",
    "https://flipboard.com/@johnsoncity6voj/johnson-city-nexus-tree-service-6vbdaiv2y",
    "https://www.digitalbusinessdirectory.online/johnson-city-nexus-tree-service",
    "https://www.bestincom.com/top-level-category/johnson-city-nexus-tree-service",
    "https://stackapps.com/users/121170/tree-service?tab=profile",
    "https://www.dennisdemo.com/home-services-1/johnson-city-nexus-tree-service",
    "http://www.countrypwr.com/johnson-city-nexus-tree-service",
    "https://www.osogbo.com/home-services-and-tradesmen/johnson-city-nexus-tree-service",
    "https://www.bpublic.com/home-services/johnson-city-nexus-tree-service",
    "https://local.gocommercially.com/home-services/johnson-city-nexus-tree-service",
    "http://www.247globalbusinesssolutions.com/home-services/johnson-city-nexus-tree-service",
    "https://www.pinterest.com/johnsonctreeservice/",
    "https://buynow-us.com/details.php?id=636137&key=873b203d9ef6a06fa212bdcaeaa12184",
    "https://issuu.com/johnsonctreeservice",
    "https://medium.com/@johnsonctreeservice",
    "http://prsync.com/johnson-city-nexus-tree-service/",
    "https://www.tripadvisor.com/Profile/johnsonctreeservice",
    "https://thelocal.directory/business-directory/johnson-city-nexus-tree-service/",
    "https://www.quora.com/profile/Johnson-City-Nexus-Tree-Service",
    "https://www.insertbiz.com/listing/johnson-city-nexus-tree-service/",
    "https://www.localhomeservicepros.com/home-improvement/johnson-city-nexus-tree-service",
    "https://meta.stackexchange.com/users/1453353/tree-service?tab=profile",
    "https://www.addonbiz.com/listing/johnson-city-nexus-tree-service/",
    "https://penzu.com/p/9a1732e6596a2594",
    "https://www.skillshare.com/en/profile/Skillshare-Member/964521592",
    "https://www.fixerhub.com/home-service/johnson-city-nexus-tree-service",
    "https://list.ly/list/9Mu2-johnson-city-nexus-tree-service?make_list_mode=true",
    "https://www.topgoogle.com/listing/johnson-city-nexus-tree-service/",
    "https://www.homemavenmember.com/local-businesses/johnson-city-nexus-tree-service",
    "https://speakerdeck.com/johnsonctreeservice",
    "https://johnsonctree.livejournal.com/profile",
    "https://soundcloud.com/johnsonctreeservice",
    "https://www.localbusinesslisting.org/johnson-city-nexus-tree-service",
    "https://webforcompany.com/johnson-city-nexus-tree-service/",
    "https://www.gravitysplash.com/b/johnson-city-nexus-tree-service/",
    "https://www.meetyourmarkets.com/johnson-city-nexus-tree-service",
    "https://www.findabusinesspro.com/general-business-1/johnson-city-nexus-tree-service",
    "https://www.techdirectory.io/home-services/johnson-city-nexus-tree-service",
    "https://helpsellmyfsbo.com/johnson-city-nexus-tree-service",
    "https://www.boisemeridian.com/top-level-category/johnson-city-nexus-tree-service",
    "https://www.detroitbusinesscenter.com/services/johnson-city-nexus-tree-service",
    "https://www.buffalosbest.com/home-services/johnson-city-nexus-tree-service",
    "https://www.gunspace.net/johnson-city-nexus-tree-service",
    "https://www.rstm.com/home-services-1/johnson-city-nexus-tree-service",
    "https://www.myadbay.com/home-services/johnson-city-nexus-tree-service",
    "https://www.frontofthebeat.com/professional-development/johnson-city-nexus-tree-service",
    "http://www.pagebook.ws/johnson-city-nexus-tree-service",
    "https://www.constantcard.com/johnson-city-nexus-tree-service"
]
const DirectoriesOnTheWeb = () => {
    return (
        <>
            <SEO/>
            <PageHeader header={"Find us on the web!"}/>
            <div className="main_wrapper">
                <Box py={20} mt={0}>
                    <List spacing={3} textAlign={'center'}>
                        {urls.map((url, index) => (
                            <ListItem key={index}>
                                <Link href={url} isExternal color="blue.500" target={"_blank"} rel={"noreferrer"}>
                                    {url}
                                </Link>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </div>
        </>
    );
};

export default DirectoriesOnTheWeb;