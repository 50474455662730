import React from "react";
import {Heading, List, ListItem, Stack, Text} from "@chakra-ui/react";

export const BlogPost4 = () => {
    return (
        <Stack spacing={3}>
            <Text>Do you have a stump on your property that you'd like to remove? Stump grinding is an efficient way to
                deal with this issue, improving both your property's aesthetics and safety. But what does the process
                entail?</Text>
            <Heading as="h3" fontSize={'xl'}>Here is what you should know about stump grinding:</Heading>
            <List styleType="disc">
                <ListItem>Stump grinding is quicker and less invasive than stump removal, which can leave a large hole
                    in your yard.</ListItem>
                <ListItem>Professional stump grinding services use high-tech equipment to grind the stump into wood
                    chips, which can be used as mulch.</ListItem>
                <ListItem>Grinding doesn't remove the roots, but they generally decay over time and are less problematic
                    than the stump.</ListItem>
                <ListItem>A well-ground stump can be covered with soil or grass and will eventually blend into the rest
                    of the lawn.</ListItem>
                <ListItem>Stump grinding enhances yard safety and aesthetics, removing tripping hazards and unsightly
                    stumps.</ListItem>
            </List>
            <Text>If you're considering getting rid of an old stump, Johnson City Tree Service provides professional
                stump grinding services. We're here to help you reclaim your property's beauty and safety!</Text>
        </Stack>
    )
}