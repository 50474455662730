import React from "react";
import {Heading, List, ListItem, Stack, Text} from "@chakra-ui/react";

export const BlogPost1 = () => {
    return (
        <Stack spacing={3}>
            <Text>As Johnson City residents, we get to experience a unique blend of seasons that affect our local tree
                life. Whether you're a gardening enthusiast or not, understanding tree pruning and its significance is
                crucial for maintaining the aesthetics and health of your trees.</Text>
            <Heading as="h3" fontSize={'xl'}>Here is your guide to tree pruning:</Heading>
            <List styleType="disc">
                <ListItem>Understanding when to prune is essential. Pruning during the late fall or winter, when the
                    tree is dormant, minimizes stress on the tree and reduces the risk of disease.</ListItem>
                <ListItem>Pruning isn't just about aesthetics. Regular pruning helps prevent diseases and pests from
                    infesting the tree.</ListItem>
                <ListItem>Always clean your tools before pruning, and between each cut. This simple step can prevent the
                    spread of diseases and pests.</ListItem>
                <ListItem>Prune with a purpose. Whether you're pruning to enhance a tree's shape, remove dead or
                    diseased branches, or to increase air and sunlight, be clear about your goal to avoid
                    over-pruning.</ListItem>
                <ListItem>When in doubt, consult a professional. Incorrect pruning can harm your tree, so it's better to
                    get professional advice if you're uncertain.</ListItem>
            </List>
            <Text>With the right knowledge and technique, pruning your trees can enhance their health and beauty.
                Johnson City Tree Service specialists are always here to guide you!</Text>
        </Stack>
    )
}